var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "direct-flight-select mb-1 justify-content-between ml-1"
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-warning",
    attrs: {
      "switch": "",
      "disabled": _vm.isDisabledGroupedItinerary
    },
    on: {
      "change": function change(val) {
        return _vm.handleUpdate(val);
      }
    },
    model: {
      value: _vm.isChecked,
      callback: function callback($$v) {
        _vm.isChecked = $$v;
      },
      expression: "isChecked"
    }
  }, [_c('span', {
    class: "text-nowrap text-body font-weight-bold cursor-pointer"
  }, [_vm._v(_vm._s(_vm.$t('flight.isSplitTripFare')))])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }